import React from 'react';
import Hero from '../Hero';
import SocialProof from '../SocialProof';
import Features from '../Features';
import Bonus from '../Bonus';
import Reviews from '../Reviews';
import Pricing from '../Pricing';
import FAQ from '../FAQ';
import Footer from '../../Layout/Footer';
import GravityForm from "../../../images/supported/gravity_form.png";
import GravityFormW from "../../../images/supported/gravity_form.webp";
import UltimateGDPR from "../../../images/supported/Ultimate_GDPR_CCPA.png";
import UltimateGDPRW from "../../../images/supported/Ultimate_GDPR_CCPA.webp";
import GDPRW from "../../../images/features/GDPR.webp";
import GDPR from "../../../images/features/GDPR.png";
import GooglePartnerBadgeW from "../../../images/GooglePartnerBadge.webp";
import GooglePartnerBadge from "../../../images/GooglePartnerBadge.png";
import { LandingPageProvider } from '../LandingPageContext';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreData } from '../../../store/auth';
import { useNavigate } from 'react-router-dom';

function GravityFormPage() {
    const { prices, isAuthenticated } = useSelector(
        (state) => state.auth.data || {}
    );
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const planId = "starterp";
    const handleCTAClick = () => {
        dispatch(
            setStoreData({
                data: {
                    flowSelected: 'paid',
                    showPayment: true,
                    selected_plan: planId,
                },
            })
        );

        let price = prices[planId];
        let payload = {
            selected_plan: planId,
            product_price: price['product_price'],
            original_price: price['original_price'],
            paypal_plan_id: price['paypal'],
            stripe_plan_id: price['stripe'],
            msrp: price['msrp'],
            membership: price['membership'],
            title: price['title'],
            subtitle: price['subtitle'],
        };
        dispatch(
            setStoreData({
                data: {
                    ...payload,
                },
            })
        );
        if (!isAuthenticated) {
            dispatch(
                setStoreData({
                    data: {
                        showSignup: true,
                    },
                })
            );
            return;
        }
        navigate('/payment');

    };
    return (
        <LandingPageProvider
            planId={planId}
            handleCTAClick={handleCTAClick}
        >

            <div data-theme="gravity2">
                <Hero
                    heading={
                        <>
                            Supercharge Your{' '}
                            <span className="text-landingAccent500">Gravity Forms</span>{' '}
                            with AI-Powered UTM Tracking We Provide
                        </>
                    }
                    subheading="One-Click Integration to Boost Lead Attribution and Maximize Conversions"
                    offerText="Limited Time Offer: Get Exclusive Bonuses Worth $899 Free Today!"
                    buttonText="Get Started Now"
                />
                <SocialProof carouselItems={[
                    {
                        src: GravityFormW,
                        fallback: GravityForm,
                        alt: "Gravity Form",
                    },
                    {
                        src: UltimateGDPRW,
                        fallback: UltimateGDPR,
                        alt: "Ultimate GDPR & CCPA",
                    },
                    {
                        src: GDPRW,
                        fallback: GDPR,
                        alt: "GDPR",
                    },
                    {
                        src: GooglePartnerBadgeW,
                        fallback: GooglePartnerBadge,
                        alt: "Google Partner Badge",
                    },

                ]} />
                <Features />
                <Bonus />
                <Reviews />
                <Pricing title='Get HandL UTM Grabber Today'
                    subtitle='HandL UTM Grabber Plugin with Gravity Forms Integration'
                    perksItems={[
                        'One-click integration with Gravity Forms',
                        'Custom parameter tracking',
                        'First and last touch UTM capture',
                        'Continuous page-to-page tracking',
                        'GDPR and CCPA compliance',
                        'Unlimited data collection in WordPress',
                        'Seamless CRM integration',
                        'Can be used on three websites',
                        <span key='bonus'>
                            Exclusive <span className='text-green-500'>Bonuses</span> Worth $899
                        </span>,
                        'Total Value: $1,299',
                    ]}
                    oldPrice='$1,299'
                    price='$399'
                    buttonText='Supercharge My Gravity Forms Now!'
                    offerEndsText='Offer ends in'
                    definedTimeStamp={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1, 0, 0, 0)}
                />
                <FAQ />
                <Footer />
            </div>
        </LandingPageProvider>
    );
}

export default GravityFormPage;
