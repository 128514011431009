import React, { useRef, useState } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import ReactPlayer from 'react-player'
import click from '../../../images/lottie/click.json'
import parameter from '../../../images/lottie/parameter.json'
import path from '../../../images/lottie/path.json'
import network from '../../../images/lottie/network.json'
import privacy from '../../../images/lottie/privacy.json'
import cloud_storage from '../../../images/lottie/cloud_storage.json'
import handshake from '../../../images/lottie/handshake.json'
import Lottie from 'lottie-react';
import useWindowWidth from '../../../hooks/global/useWindowWidth'

const items = [
    {
        title: "One-Click Integration",
        description: "Add over 20+ tracking parameters to all your Gravity Forms with just one click.",
        icon: click,
        video: "https://www.youtube.com/watch?v=v5z_p7l0G0k",
    },
    {
        title: "Custom Parameter Tracking",
        description: "Bring your own custom parameters (affiliate IDs, partner tracking, etc.) and track them just like UTMs.",
        icon: parameter,
        video: "https://www.youtube.com/watch?v=v5z_p7l0G0k",
    },
    {
        title: "First Touch and Last Touch Attribution",
        description: "Capture both first and last touch UTMs for accurate lead attribution.",
        icon: path,
        video: "https://www.youtube.com/watch?v=v5z_p7l0G0k",
    },
    {
        title: "Page-to-Page Tracking",
        description: "Never lose data as users navigate your site—track every step effortlessly.",
        icon: network,
        video: "https://www.youtube.com/watch?v=v5z_p7l0G0k",
    },
    {
        title: "Privacy-Aware Data Collection",
        description: "Fully GDPR and CCPA compliant to ensure user data privacy.",
        icon: privacy,
        video: "https://www.youtube.com/watch?v=v5z_p7l0G0k",
    },
    {
        title: "Unlimited Data Storage in WordPress",
        description: "Collect and store all your lead data directly in WordPress without submission limits.",
        icon: cloud_storage,
        video: "https://www.youtube.com/watch?v=v5z_p7l0G0k",
    },
    {
        title: "Seamless CRM Integration",
        description: "Pass data to your favorite CRMs like Salesforce, GoHighLevel, HubSpot, Infusionsoft, Zoho, and more.",
        icon: handshake,
        video: "https://www.youtube.com/watch?v=v5z_p7l0G0k",
    }

]
function Index() {
    const windowWidth = useWindowWidth();
    return <section class="relative py-16 overflow-hidden">
        <div class="2xl:max-w-[87.5rem] px-4 mx-auto relative">
            <h2 class="mx-auto text-4xl 2xl:text-5xl font-semibold text-center mb-16 text-slate-800">Unlock the Full Potential of Gravity Forms with HandL UTM Grabber</h2>
            {
                windowWidth > 1024 ? <FeatureTabs /> : <FeatureAccordions />
            }
        </div>
    </section>

}
function FeatureAccordions() {
    const [activePanel, setActivePanel] = useState(0);
    return <div className={"grid grid-cols-1 lg:grid-cols-12"}>
        <div className={"col-span-5 grid grid-rows-1 gap-2"}>
            {
                items.map((item, i) => (<div key={item.title} defaultOpen={i === 0} as="div" className="" >
                    <AccordionButton

                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                        onClick={() => {
                            if (activePanel === i) {
                                setActivePanel(-1)
                            }
                            else {
                                setActivePanel(i);
                            }
                        }}
                        active={activePanel === i}
                    />
                    {
                        activePanel === i &&
                        <div className={"col-span-7 mt-5 lg:mt-0 lg:ml-[6%] w-full h-full "}>
                            <div className='relative aspect-video max-h-[calc(90vh)]'>
                                
                            <ReactPlayer url={item.video} controls={true} width={"100%"} height={"100%"} style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                            }} />
                            </div>
                        </div>
                    }

                </div>))
            }
        </div>
    </div>
}
function FeatureTabs() {
    return (
        <TabGroup className={"grid grid-cols-1 lg:grid-cols-12"}>
            <TabList className={"col-span-5 2xl:col-span-4 grid grid-rows-1 gap-2"}>
                {
                    items.map((_, i) => (
                        <FeaturesTab
                            key={"feat_tab" + i}
                            title={_.title}
                            description={_.description}
                            icon={_.icon}
                        />))
                }
            </TabList>
            <TabPanels className={"col-span-7 2xl:col-span-8 mt-12 lg:mt-0 lg:ml-[6%]"}>
                {
                    items.map((_, i) => (<TabPanel key={"feat_tabpanel" + i} className={"relative pb-[56.25%]"}>
                        <ReactPlayer url={_.video} controls={true} width={"100%"} height={"100%"} style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                        />
                    </TabPanel>))
                }
            </TabPanels>
        </TabGroup>
    )
}
const FeaturesTab = ({ title, icon, description }) => {
    const lottieRef = useRef()
    return <Tab onClick={() => {
        lottieRef.current?.goToAndPlay(0, true)
    }} className="grid gap-4 grid-cols-6 items-center justify-center text-left text-lg 2xl:text-xl font-semibold p-7 rounded-md border-b-gray-200 border-transparent border text-slate-700 data-[selected]:bg-[#f6f6f6]">
        <Lottie loop={false} lottieRef={lottieRef} className="col-span-1 my-auto mx-auto" animationData={icon} />
        <div className="col-span-5">
            {title}
            <span className='text-base font-normal block mt-[5px]'>{description}</span>
        </div>
    </Tab>
}
const AccordionButton = ({ title, icon, description, onClick, active }) => {
    const lottieRef = useRef()
    return <button onClick={(e) => {
        lottieRef.current?.goToAndPlay(0, true)
        onClick()
        e.stopPropagation()
    }}
        className={`grid gap-4 grid-cols-6 items-center justify-center text-left text-lg 2xl:text-xl font-semibold p-5 sm:p-7 rounded-md border-b-gray-200 border-transparent border text-slate-700 ${active && "bg-[#f6f6f6]"}`}>
        <Lottie loop={false} lottieRef={lottieRef} className="col-span-1 my-auto mx-auto" animationData={icon} />
        <div className="col-span-5">
            {title}
            <span className='text-base font-normal block mt-[5px]'>{description}</span>
        </div>
    </button>
}
export default Index