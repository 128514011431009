import React, { useEffect } from 'react'
import SocialProofCarousel from './SocialProofCarousel';

function SocialProof({carouselItems}) {
    useEffect(() => {
        function counter() {
            const counters = document.querySelectorAll(".counter-value");
            const speed = 250;

            if (counters.length) {
                counters.forEach((counter) => {
                    const target = +counter.getAttribute("data-target");
                    const inc = target / speed;

                    let count = 0;
                    const updateCount = () => {

                        count += inc;
                        if (count < target) {
                            counter.innerText = numberWithCommas(count.toFixed(0));
                            setTimeout(updateCount, 1);
                        } else {
                            counter.innerText = numberWithCommas(target);
                        }
                    };
                    updateCount();
                });
            }

            function numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        }
        counter();
    }, [])
    return (
        <section className="relative py-16 overflow-hidden bg-fixed bg-center bg-cover bg-pattern-wave">
            <div className="absolute inset-0 bg-landingAccent2-950/80 mix-blend-darken"></div>
            <div className="absolute -top-24 -right-56">
                <div className="flex items-center justify-center rounded-full h-[30rem] w-[30rem] bg-landingAccent2-900/10">
                    <div className="flex items-center mx-auto rounded-full h-[22rem] w-[22rem] bg-landingAccent2-950/80">
                        <div className="flex items-center justify-center mx-auto rounded-full h-[15rem] w-[15rem] bg-landingAccent2-900/10"></div>
                    </div>
                </div>
            </div>
            <div className="2xl:max-w-[87.5rem] px-4 mx-auto relative text-center">
                <div className='flex flex-col items-center text-white'>

                    <h2 className='mb-5 2xl:text-4xl text-3xl font-semibold leading-9'>
                        Trusted by <strong className='text-[50px] mx-2 leading-none align-middle counter-value block sm:inline' data-target="100000"></strong> Agencies in whole world
                    </h2>

                    <h2 className='mb-2 text-xl lg:text-2xl 2xl:text-3xl flex flex-row items-center' >+ <span data-target="100" className='counter-value'></span><span className="flex gap-1 mx-1 align-middle">
                        {Array(5).fill().map((_, i) => (<svg key={i} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-lucide="star" className="lucide lucide-star w-7 h-7 text-yellow-500 fill-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>))}
                    </span> Reviews</h2>
                    <h2 className='mb-7 text-lg 2xl:text-2xl flex flex-row items-center' >
                        Proud Google Partner
                    </h2>
                    <SocialProofCarousel items={carouselItems}/>
                </div>
            </div>
        </section>
    )
}

export default SocialProof